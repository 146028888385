import '~/assets/styles/tailwind.css'

import { hydrate, render } from 'react-dom'
import type { PageContextBuiltInClient } from 'vite-plugin-ssr/client'
import { useClientRouter } from 'vite-plugin-ssr/client/router'

import { convertLayoutComponent } from '~/util/common/layout'

import { PageShell } from './PageShell'
import type { PageContext } from './types'

// hydrate in client
useClientRouter({
    async render(pageContext: PageContext & PageContextBuiltInClient) {
        // We do Server Routing, but we can also do Client Routing by using `useClientRouter()`
        // instead of `getPage()`, see https://vite-plugin-ssr.com/useClientRouter
        const { Page, pageProps, pageExports } = pageContext

        const LayoutComponent = convertLayoutComponent(pageExports.layout)

        const content = (
            <PageShell pageContext={pageContext}>
                <LayoutComponent>
                    <Page {...pageProps} />
                </LayoutComponent>
            </PageShell>
        )

        const container = document.getElementById('page-view')!

        if (pageContext.isHydration) hydrate(content, container)
        else render(content, container)
    },
    // ensureHydration: true,
    // prefetchLinks: true,
    onTransitionStart() {
        // console.log('Page transition start')
        // For example:
        document.body.classList.add('page-transition')
    },
    onTransitionEnd() {
        // console.log('Page transition end')
        // For example:
        document.body.classList.remove('page-transition')
    },
}).hydrationPromise.then(() => {
    console.log('Hydration finished; page is now interactive')
})
