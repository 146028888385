import { Box } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

import { GFooter } from '~/components/global/gFooter/GFooter'
import { GHeader } from '~/components/global/gHeader/GHeader'

type Props = {
    children?: ReactNode
}

const Content = ({ children }: { children: ReactNode }) => (
    <Box paddingTop={{ base: '48px', md: '73px' }} minHeight={{ base: 'calc(100vh - 168.66px)', md: 'calc(100vh - 188px)' }}>
        {children}
    </Box>
)

export const Default: FC<Props> = ({ children }) => {
    return (
        <>
            <GHeader />
            <Content>{children}</Content>
            <GFooter />
        </>
    )
}
