import { keyframes } from '@emotion/react'

export const cFadeMenu = keyframes`
  from {
    opacity: 0;
    background: transparent
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    background: white
    transform: translate3d(0, 50%, 0);
  }
`
