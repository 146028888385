import './PageShell.css'
import '~/assets/styles/globals.css'

import { ChakraProvider } from '@chakra-ui/react'
import { Provider as JotaiProvider } from 'jotai'
import { type ReactNode, StrictMode } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

import { convertInitialStateToAtom } from '~/store'
import { customTheme } from '~/util/chakra-theme'

import type { PageContext } from './types'
import { PageContextProvider } from './usePageContext'

const queryClient = new QueryClient()

export const PageShell = ({ children, pageContext }: { children: ReactNode; pageContext: PageContext }) => {
    return (
        <StrictMode>
            <QueryClientProvider client={queryClient}>
                <ChakraProvider theme={customTheme}>
                    <JotaiProvider initialValues={convertInitialStateToAtom(pageContext.initialState)}>
                        <PageContextProvider pageContext={pageContext}>{children}</PageContextProvider>
                    </JotaiProvider>
                </ChakraProvider>
            </QueryClientProvider>
        </StrictMode>
    )
}
