import { Box, Container, Flex, Image, Link, Text } from '@chakra-ui/react'
import type { VFC } from 'react'

import footerLogo from '~/assets/image/footer/footer-logo.svg'

export type GFooterViewProps = {} & GFooterProps

export const GFooterView: VFC<GFooterViewProps> = () => {
    const footerLinkList = [
        {
            title: 'プライバシーポリシー',
            href: 'privacypolicy',
        },
        {
            title: 'サイト利用規約',
            href: 'terms',
        },
        {
            title: 'ソーシャルメディアポリシー',
            href: 'social-policy',
        },
    ]

    return (
        <>
            <Container data-testid="c-footer" as="footer" role="contentinfo" py={'10'} px={'30px'} textAlign={'center'}>
                <Image src={footerLogo} mx={'auto'} alt="人を、想う力。街を、想う力。三菱地所グループ" />
                <Flex
                    mt={'1.5rem'}
                    justifyContent={'space-between'}
                    gap={{ base: '1rem', md: '1.5rem' }}
                    flexDir={{ base: 'column', md: 'row' }}>
                    {footerLinkList.map((it, idx) => (
                        <Link
                            whiteSpace={'nowrap'}
                            key={idx}
                            href={it.href}
                            fontSize={{ base: 'xs', md: 'sm' }}
                            lineHeight={1}
                            color={'text.gray'}
                            isExternal>
                            {it.title}
                        </Link>
                    ))}
                </Flex>
                <Box mt={{ base: '4', md: '6' }}>
                    <Text fontSize={'10px'} color={'text.dark'} fontFamily="Noto Sans JP" letterSpacing="0.05em">
                        Copyright ©MEC Industry Co., Ltd. All rights reserved.
                    </Text>
                </Box>
            </Container>
        </>
    )
}

export type GFooterProps = {}

export const GFooter: VFC<GFooterProps> = (props) => {
    return <GFooterView {...props} />
}
