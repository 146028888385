import { authTokenAtom } from '~/store/auth'

export type StoreInitialState = {
    auth?: {
        authToken?: string
    }
}

export const convertInitialStateToAtom = (initialState: StoreInitialState) => {
    return [[authTokenAtom, initialState?.auth?.authToken]] as const
}
