import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

export const customTheme = extendTheme({
    colors: {
        'mi-blue': '#0096CD',
        'mi-yellow': '#FFD500',
        background: '#ffffff',
        dark: {
            500: '#1A1311',
        },
        light: '#FFFFFF',
        gray: '#AAAAAA',
        black: {
            500: '#000000',
        },
        border: '#D7D7D7',
        error: '#D72700',
        'error-fill': {
            500: '#FBE9E6',
        },
        focus: '#38ADFF',
        disable: {
            500: '#D6D3D0',
        },
        success: '#00D7B0',
        text: {
            dark: '#1A1311',
            light: '#ffffff',
            gray: '#707070',
            link: '#0D75B7',
        },
    },

    lineHeights: {
        x130: '130%',
        x150: '150%',
    },
    letterSpacing: {
        x01: '0.1em',
    },

    components: {
        Checkbox: {
            baseStyle: {
                control: {
                    _focus: {
                        boxShadow: '0 0 0 2px #38ADFF',
                    },
                },
            },
        },
    },
})

export const breakpoints = createBreakpoints({
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
})
